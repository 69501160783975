export function sortByName(a, b) {
    var nameA = a.data.name;
    var nameB = b.data.name;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }