import styled from "@emotion/styled"


export const Underline = styled.div`
  height: 1px;
  background: black;
  width: 100px;
  margin: 0 auto;
`

export const Heading = styled.div`
  text-align: center;
  width: 100%;
  text-transform: uppercase;
`

export const Container = styled.div`

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .column img {
    padding: 30px;
    width: 100%;
  }
`
