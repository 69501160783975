import styled from "@emotion/styled"

export const Container = styled.div`
  text-align: center;
`

export const Title = styled.h2`
  font-weight: 500;

  a {
    color: black;
    text-decoration: none;
  }
`

export const Subheading = styled.h4`
  color: grey;
  font-family: "Raleway";
  font-weight: normal;
  margin: 8px 0;
`

export const Divider = styled.div`
  height: 1px;
  width: 50px;
  background: grey;
  margin: 8px auto;
`

export const Image = styled.div`
  width: 100%;
  height: 500px;
  margin: 0 !important;
  background: url(${({ src }) => src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;

  @media only screen and (max-width: 480px) {
    max-height: 200px;
  }
`
