import React from "react"

import { ArrowLeftDiv } from "./slider.css"
import ChevronLeft from "./chevron-left.svg"

const ArrowLeft = ({ goLeft }) => (
  <ArrowLeftDiv onClick={goLeft}>
    <ChevronLeft />
  </ArrowLeftDiv>
)

export default ArrowLeft
