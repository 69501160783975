import { Builder } from "@builder.io/react"
import Newsletter from "./newsletter"

Builder.registerComponent(Newsletter, {
  name: "Newsletter",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0",
  inputs: [
    {
      name: "sectionName",
      type: "string"
    },
    {
      name: "heading",
      type: "string",
      defaultValue: "Subscribe to our newsletter",
    },
    {
      name: "placeholder",
      type: "string",
      defaultValue: "Your email here",
    },
    {
      name: "submit",
      type: "string",
      defaultValue: "Subscribe",
    },
    {
      name: "image",
      type: "file",
      allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
      required: true,
      defaultValue:
        "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F52dcecf48f9c48cc8ddd8f81fec63236",
    },
  ],
})
