import styled from "@emotion/styled"

export const ArrowLeftDiv = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  width: 15%;
  cursor: pointer;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    svg {
      opacity: 1;
    }
  }

  svg {
    opacity: 0.5;
    width: 40px;
    height: 52px;
  }
`

export const ArrowRightDiv = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  width: 15%;
  cursor: pointer;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    svg {
      opacity: 1;
    }
  }

  svg {
    opacity: 0.5;
    width: 40px;
    height: 52px;
  }
`

export const Container = styled.div`
  position: relative;
  overflow: hidden;
`

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
`

export const IndicatorsContainer = styled.ol`
  position: absolute;
  right: 0;
  bottom: 15.5em;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  margin: 0 auto;
`

export const Indicator = styled.li`
  position: relative;
  flex: 0 1 auto;
  width: 2.5em;
  height: 0.1em;
  margin: 0;
  background: white;
  cursor: pointer;
  opacity: 0.5;

  .active {
    opacity: 1;
    height: 0.15em;
  }
`

export const Slide = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  color: white;
  margin: 16px;

  ${({ fixed }) => fixed && `width: 33%;`}

  p {
    color: black;
    margin: 16px 0;
    font-family: "IM Fell Double Pica" !important;

    @media only screen and (max-width: 480px) {
      text-align: center;
    }
  }

  a {
    color: black;
    text-transform: uppercase;
  }
`

export const CarouselItem = styled.div`
  width: 500px;
`

export const SlidesContainer = styled.div`
  display: flex;
`

export const Image = styled.div`
  width: 100%;
  height: 180px;
  margin: 0 !important;
  border-radius: 6px;
  background: url(${({ src }) => src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
`
