import { Builder } from "@builder.io/react"
import Slider from "./slider"

Builder.registerComponent(Slider, {
  name: "Slider",
  inputs: [
    {
      name: "sectionName",
      type: "string",
      defaultValue: ""
    },
    {
      name: "heading",
      type: "string",
      defaultValue: ""
    },
    {
      name: "brands",
      type: "list",
      defaultValue: [],
      copyOnAdd: false,
      subFields: [
        {
          name: "brand",
          type: "reference",
          modelId: "c454d7f30c62401d93dd2b4477cef0a3",
          defaultValue: {
            "@type": "@builder.io/core:Reference",
            "model": "brand"
          },
        },
      ],
    },
    {
      name: "articles",
      type: "list",
      defaultValue: [],
      copyOnAdd: false,
      subFields: [
        {
          name: "article",
          type: "reference",
          modelId: "fab8c26226a84ff5a0745a16e55097a0",
          defaultValue: {
            "@type": "@builder.io/core:Reference",
            "model": "article"
          },
        },
      ],
    },
    {
      name: "maxArticles",
      type: "number",
      defaultValue: 16
    },
  ],
})
