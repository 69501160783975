import { builder } from "@builder.io/react"
import "@builder.io/widgets"

import "./components/Hero/builder"
import "./components/Newsletter/builder"
import "./components/LatestArticles/builder"
import "./components/Brands/builder"
import "./components/Slider/builder"
import "./components/MoreArticles/builder"

import config from "./config"

builder.init(config.builderAPIKey)
