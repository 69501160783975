import styled from "@emotion/styled"
import Logo from "./logo.inline.svg"
import FBLogo from "./fbicon.inline.svg"
import TWLogo from "./twicon.inline.svg"
import LILogo from "./liicon.inline.svg"
import YTLogo from "./yticon.inline.svg"
import PILogo from "./piicon.inline.svg"
import MailIcon from "./mailicon.inline.svg"

export const Container = styled.div`
  background: #1f1f1f;
  color: white;
  text-align: center;
  text-align: center;
`

export const MenuContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  ul {
    color: grey;
  }

  a {
    color: grey;
  }

  @media only screen and (max-width: 480px) {
    flex-direction: column;

    ul {
      margin-top: 24px;
    }
  }
`

export const FLogoSVG = styled(Logo)`
  width: 130px;
  fill: #fff;
  top: 10px;
  position: relative;

  @media only screen and (max-width: 480px) {
    width: 134px;
    height: 34px;
  }
`

export const MailIconSVG = styled(MailIcon)`
  width: 30px;
`
export const FBLogoSVG = styled(FBLogo)`
  width: 30px;
`
export const TWLogoSVG = styled(TWLogo)`
  width: 30px;
`
export const LILogoSVG = styled(LILogo)`
  width: 30px;
`
export const YTLogoSVG = styled(YTLogo)`
  width: 30px;
`
export const PILogoSVG = styled(PILogo)`
  width: 30px;
`

export const InnerMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
