import React from "react"
import { Link } from "gatsby"

import {
  Underline,
  MoreArticlesContainer,
  ArticleContainer,
  Articles,
  Heading,
  Header,
} from "../Article/article.css"

const MoreArticles = props => {
  if (!props.articles) return null
  return (
    <div class="container">
      <div class="moreArticles Articles">
        <Heading className="mainTitle">{['More ', <b>Articles</b>]}</Heading>
        <Articles className="articlesContainer">
          {props.articles.map(({ article }, i) => (
            article && <ArticleContainer key={i} className="Article">
            <Link to={`/articles/${article.value?.data?.slug}`}>
              <img src={article.value?.data?.picture} />
              <div class="articleText">{article.value?.data?.title}</div>
            </Link>
          </ArticleContainer>
          ))}
        </Articles>
      </div>
    </div>
  )
}

export default MoreArticles
