import React, { useState } from "react"
import { Link } from "gatsby"
import { graphql, StaticQuery, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

import BrandsMenu from "./brands-menu"
import SectionsMenu from "./sections-menu"
import MobileMenu from "./mobile-menu"

import {
  MenuLogoContainer,
  MenuContainer,
  Container,
  LogoSVG,
  MenuSVG,
  ChevronDownSVG,
  ChevronUpSVG,
} from "./header.css"

import { DropdownMenuContainer } from "./brands-menu.css"


{/*
const noticeQuery = graphql`
  query{
    allBuilderModels {
      headerNotice(limit: 1, sort: {createdDate: -1}) {
        data {
          notice
        }
      }
    }
  }
`

            <StaticQuery query={noticeQuery}>
              {data => {
                const notice = data.allBuilderModels.headerNotice[0].data.notice
                if (notice) {
                  return (
                    { notice }
                  )
                }
              }}
              </StaticQuery>

const headerQuery = graphql`
  query{
    allBuilderModels {
      wgHeader(query: {name: "Main Header"}) {
        data {
          headerLogo
          headerMainMenu
        }
      }
    }
  }
`
  <StaticQuery query={headerQuery}>
    {data => {
      console.log(data)
      const headerLogo = data.allBuilderModels.wgHeader[0].data.headerLogo
      if (headerLogo) {
        return (
          <h1>{ headerLogo }</h1>
        )
      }
    }}
  </StaticQuery>

*/}

const menuItems = [
  {
    link: "/",
    text: "Home",
  },
  {
    text: "Sections",
    chevron: true,
    menu: <SectionsMenu />,
  },
  {
    text: "Brands",
    chevron: true,
    menu: <BrandsMenu />,
  },
  {
    link: "/latest",
    text: "Latest Releases",
  },
  {
    link: "/about-us",
    text: "About",
  },
]

const MenuDropdownItem = ({ onClick, text, isOpen }) => {
  return (
    <a onClick={onClick}>
      {text}
      {isOpen ? <ChevronUpSVG /> : <ChevronDownSVG />}
    </a>
  )
}

const Header = ({ siteTitle }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedMenuItem, setMenuItem] = useState({})

  return (
    <>
      <Container className="Header">
        <div className="headerNotice">
          <div class="container">
            We independently review everything we recommend. When you buy through our links, we may earn a commission. <a href="/about-us">Learn more</a>
          </div>
        </div>
        <div className="mobileSideMenu">
          <MobileMenu menuItems={menuItems} isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
        <MenuLogoContainer className="mainHeader">
          <div className="menuIcon">
            <MenuSVG onClick={() => setIsOpen(!isOpen)} />
          </div>
          <div className="mainLogo">
            <div className="container">
              <Link href="/">
                <LogoSVG />
              </Link>
            </div>
          </div>
          <div className="mainMenu">
            <MenuContainer className="container">
              {menuItems.map((menuItem, i) => {
                if (menuItem.link) {
                  return (
                    <Link href={menuItem.link} key={i}>
                      {menuItem.text}
                    </Link>
                  )
                }

                const isSameMenuItemClicked =
                  selectedMenuItem.text === menuItem.text

                const onClick = isSameMenuItemClicked
                  ? () => setMenuItem({})
                  : () => setMenuItem(menuItem)

                return (
                  <MenuDropdownItem
                    text={menuItem.text}
                    onClick={onClick}
                    isOpen={isSameMenuItemClicked}
                  />
                )
              })}
            </MenuContainer>
          </div>
        </MenuLogoContainer>
      </Container>
      {selectedMenuItem.menu && (
        <DropdownMenuContainer isMenuOpen={selectedMenuItem.menu} className="subMenu">
          <div className="container">
            {selectedMenuItem.menu}
          </div>
        </DropdownMenuContainer>
      )}
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
