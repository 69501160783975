import styled from "@emotion/styled"

export const Container = styled.div`
  width: 100%;
`

export const ContentContainer = styled.div`
  margin: 0 auto;
`

export const Article = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  text-align: center;

  h3 {
    margin: 0;
  }

  p {
    font-size: 0.8rem;
    margin: 0;
  }

  a {
    color: black;
    text-decoration: none;
  }

  img {
      width: 100%;
      object-fit: cover;
  }

  .item {
      width: 100%;
  }

  @media only screen and (max-width: 680px) {
    border-bottom: 1px solid white;
    text-align: left;

    img {
        width: 100%;
        height: 180px;
        object-fit: none;
    }
  }
`

export const Title = styled.div`
  text-align: center;
  margin-bottom: 20px;
`

export const Underline = styled.div`
  height: 1px;
  background: black;
  width: 100px;
  margin: 8px auto;
`

export const ArticlesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
