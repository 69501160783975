import React from "react"
import { Link } from "gatsby"
import { graphql, StaticQuery } from "gatsby"
import Slider from "react-slick"
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ReadMoreSVG from "../../images/icons/readmore.inline.svg"

import {
  Container,
  ContentContainer,
  Article,
  Underline,
  Title,
  ArticlesContainer,
} from "./latest-articles.css"

const LatestArticles = ({ heading, limit }) => {

  const articleQuery = graphql`
    query {
      allBuilderModels {
        article(limit: 18, sort: {createdDate: -1}, options: { cachebust: false }) {
          data {
            slug
            title
            picture
          }
        }
      }
    }
  `

  const articlesettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    rows: 2,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          row: 3,
        }
      }
    ]
  };


  return (
  <StaticQuery query={articleQuery}>
    {data => {
      const models = data.allBuilderModels
      if (models.article) {
        return (
        <div className="Articles container">
          <div className="trendingArticles Articles">
            <div>
              <h2 className="mainTitle">{['Top Trending ', <b>Articles</b>]}</h2>
              <div className="articlesContainer">
                <Slider {...articlesettings} className="articleSlider">
                {models.article.map((item, i) => {
                  const article = item.data
                  if(article.title  && article.slug && article.picture) {
                    return (
                      <div key={i} className="Article">
                        <a href={`/articles/${article.slug}`}>
                          <img src={article.picture} />
                          <div className="articleText">
                            {article.title.length > 60 ? article.title.substring(0, 60) + '...' : article.title}
                          </div>
                          <div className="readMore">Read more
                            <span>
                              <ReadMoreSVG/>
                            </span>
                          </div>
                        </a>
                      </div>
                    )
                  }
                  return null
              })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        )
      }
    }
  }
  </StaticQuery>
  )
}

export default LatestArticles
