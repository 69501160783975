import React from "react"

import CarouselContainer from "./carousel-container"

import { Slide, Image } from "./slider.css"

const Slider = props => {
  const { articles } = props

  return (
    <div style={{ position: "relative" }} className="container">
      <CarouselContainer
        slidesPresented={articles.length}
      >
        {articles.map(item => {
          const { article } = item
          if (!article.value) {
            return null
          }
          const slide = article.value.data
          return (
            slide && (
              <Slide>
                <Image src={slide.picture} />
                <p>{slide.title}</p>
                <a href={`/articles/${slide.slug}`}>read more</a>
              </Slide>
            )
          )
        })}
      </CarouselContainer>
    </div>
  )
}

export default Slider
