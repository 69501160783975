import React from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'

import {
  Container,
  BoxContainer,
  InputContainer,
  Input,
  Button,
  Heading,
} from "./newsletter.css"

const Newsletter = ({ heading, placeholder, submit, image }) => {
  const [subscribed, setSubscribed] = React.useState({success:false , msg: ''})

  const _handleSubmit = e => {
    e.preventDefault();
    addToMailchimp(e.target.elements[0].value)
    .then((response) => {
      if(response.result === 'success'){
        setSubscribed({success:true , msg: response.msg})
      } else{
        setSubscribed({success:false , msg: response.msg})
      }
    })
    .catch(console.error)
  }

  return <div class="container"><Container className="subscribeSection">
    <BoxContainer>
      <Heading className="subscribeText">{["Get Update by", <b>Subscribe newsletter</b>]}</Heading>
      <div class="subscribeForm">
      {!subscribed.success && <form onSubmit={_handleSubmit}>
        <InputContainer>
          <Input placeholder={"Your email here"} name="email" type="email"/>
          <Button type="submit">{"Subscribe"}</Button>
        </InputContainer>
      </form> }
      {subscribed.success && <p>{subscribed.msg}</p>}
      </div>
    </BoxContainer>
  </Container></div>
}

export default Newsletter
