import React, { useState, useEffect } from "react"

import { Container, Subheading, Divider, Image, Title } from "./hero.css"
import ReadMoreSVG from "../../images/icons/readmore.inline.svg"

const Hero = props => {

  if(!props.article || !props.article.value || !props.article.value.data){
    return null
  }
  const article = props.article.value.data
  const floatingimage = props.image

  return (
    <>
      <div className={(article.picture) ? "heroSection extraSpace" : "heroSection littleSpace"} >
        <div class="container">
          <div class="heroSlider">
            <div class="heroSlide">
              <div class="heroSlideBack"></div>
              <div class="heroSlideContent">
                <div class="heroText">
                  <h2 class="heroHeading">{article.title}</h2>
                  <p class="heroSummary">{article.summary}</p>
                  <a href={`/articles/${article.slug}`}>
                    <button class="heroLink">Read More
                      <span>
                        <ReadMoreSVG/>
                      </span>
                    </button>
                  </a>
                </div>
                <div class="heroImage">
                  <img class="heroMainImage" src={article.picture} />
                  {floatingimage ?
                    <img class="heroFloatingImage" src={floatingimage} />
                    : null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*
      <a href={`/articles/${article.slug}`}><Image src={article.picture} /></a>
      <Container>
        <Title><a href={`/articles/${article.slug}`}>{article.title}</a></Title>
        <Divider />
        <Subheading>

        </Subheading>
      </Container>
      */}
    </>
  )
}

export default Hero
