import React from "react"
import { graphql, StaticQuery } from "gatsby"

import { Container } from "./brands-menu.css"
import { sortByName } from "./sort"

const brandQuery = graphql`
  query {
    allBuilderModels {
      brand(limit: 30, query: {data: {featured: true}}, options: { cachebust: false }) {
        data {
          name
        }
      }
      article(limit: 3, sort: {createdDate: -1}, options: { cachebust: false }) {
        data {
          slug
          picture
          title
        }
      }
    }
  }
`

const BrandMenu = ({ isMenuOpen }) => {
  return (
    <StaticQuery query={brandQuery}>
      {data => {
        const models = data.allBuilderModels
        if (models.brand && models.article) {
          models.brand.sort(sortByName)
          return (
            <div class="subMenuContent">
              <div class="subMenuLinks">
                <h2>Top Brands</h2>
                <div class="menuItems">
                {models.brand.map((item, i) => {
                  const brand = item.data
                  if (brand.name) {
                    return (
                      <a
                        key={i}
                        href={`/brands/${brand.name
                          .toLowerCase()
                          .replace(/\s/g, "")}`}
                      >
                        {brand.name}
                      </a>
                    )
                  }
                  return null
                })}
                </div>
              </div>
              <div class="subMenuArticles">
                <h2>New Release</h2>
                <div class="Articles">
                {models.article.map((item, i) => {
                  const article = item.data
                  if (article.picture) {
                    return (
                    <div class="Article">
                      <a key={i} href={`/articles/${article.slug}`}>
                        <img src={article.picture} alt={article.name} />
                        <span>{article.title}</span>
                      </a>
                    </div>
                    )
                  }
                  return null
                })}
                </div>
              </div>
            </div>
          )
        }
      }}
    </StaticQuery>
  )
}

export default BrandMenu
