import styled from "@emotion/styled"

export const DropdownMenuContainer = styled.div`
  max-height: 0;
  transition: all 0.15s ease-out;
  overflow: hidden;

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    `
    max-height: 500px;
  `}
`
