import styled from "@emotion/styled"

export const Container = styled.div`
  text-align: left;
  position: relative;
  align-items: center;
`

export const BoxContainer = styled.div`
  position: relative;
  width: 100%;
`

export const Heading = styled.div`
  color: black;
  margin-bottom: 8px;
  font-size: 36px;/*1.2rem;*/
  line-height: 57px;
`

export const InputContainer = styled.div`
  position: relative;
`

export const Input = styled.input`
  width: 100%;
`

export const Button = styled.button`
  position: absolute;
  cursor: pointer;
  background: rgb(157, 157, 157);
  background: rgb(78, 78, 78);
  background: -moz-linear-gradient(
    345deg,
    rgba(78, 78, 78, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    345deg,
    rgba(78, 78, 78, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    345deg,
    rgba(78, 78, 78, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4e4e4e",endColorstr="#000000",GradientType=1);

  &:hover {
    background: black;
  }
`
