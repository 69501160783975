import React from "react"

import { useCarousel, makeIndices } from "./useCarousel"
import ArrowLeft from "./arrow-left"
import ArrowRight from "./arrow-right"

import { Container, ImagesContainer, CarouselItem } from "./slider.css"

const CarouselContainer = ({
  children,
  slidesPresented = 1,
  interval = 10000,
  slideWidth,
}) => {
  const slides = React.Children.toArray(children)
  const length = slides.length
  const numActive = Math.min(length, slidesPresented)
  const [active, setActive, handlers, style] = useCarousel(length, interval, {
    slidesPresented: numActive,
    slideWidth,
  })

  const afterIndices = makeIndices(0, +1, numActive)

  return (
    length > 0 && (
      <Container>
        <ArrowLeft
          goLeft={() => setActive(active === 0 ? length - 1 : active - 1)}
        />
        <ArrowRight
          goRight={() => setActive(active === length - 1 ? 0 : active + 1)}
        />
        <ImagesContainer {...handlers} style={style}>
          {afterIndices.map(i => (
            <CarouselItem className="inactive carouselItem">
              {slides[i]}
            </CarouselItem>
          ))}
          {slides.map((slide, index) => (
            <CarouselItem
              className={`${
                index === active ? "active" : "inactive"
              } carouselItem`}
            >
              {slide}
            </CarouselItem>
          ))}
          {afterIndices.map(i => (
            <CarouselItem className="inactive carouselItem">
              {slides[i]}
            </CarouselItem>
          ))}
        </ImagesContainer>
      </Container>
    )
  )
}

export default CarouselContainer
