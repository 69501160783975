// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-brands-js": () => import("./../../../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-edit-article-js": () => import("./../../../src/pages/_edit_/article.js" /* webpackChunkName: "component---src-pages-edit-article-js" */),
  "component---src-pages-latest-js": () => import("./../../../src/pages/latest.js" /* webpackChunkName: "component---src-pages-latest-js" */),
  "component---src-pages-sections-js": () => import("./../../../src/pages/sections.js" /* webpackChunkName: "component---src-pages-sections-js" */),
  "component---src-templates-article-page-jsx": () => import("./../../../src/templates/ArticlePage.jsx" /* webpackChunkName: "component---src-templates-article-page-jsx" */),
  "component---src-templates-brand-page-jsx": () => import("./../../../src/templates/BrandPage.jsx" /* webpackChunkName: "component---src-templates-brand-page-jsx" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-section-page-jsx": () => import("./../../../src/templates/SectionPage.jsx" /* webpackChunkName: "component---src-templates-section-page-jsx" */)
}

