import React, { useState } from "react"
import { Link } from "gatsby"

import {
  ChevronUpSVG,
  ChevronDownSVG,
  StyledMobileMenu,
  MobileMenuDropdownContainer,
} from "./header.css"

const MenuDropdownItem = ({ text, chevron, menu }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <a onClick={() => setIsOpen(!isOpen)} className="bm-item">
      {text}
      {isOpen ? <ChevronUpSVG /> : <ChevronDownSVG />}
      {isOpen && (
        <MobileMenuDropdownContainer>{menu}</MobileMenuDropdownContainer>
      )}
    </a>
  )
}

const MenuMobile = ({ menuItems, isOpen, setIsOpen }) => {
  const onClick = () => {}

  return (
    <StyledMobileMenu isOpen={isOpen} onClose={() => setIsOpen(false)}>
      {menuItems.map(({ link, text, chevron, menu }, i) => {
        if (link) {
          return (
            <Link href={link} key={i}>
              <span class="subMenuLink">{text}</span>
            </Link>
          )
        }

        return (
          <MenuDropdownItem key={i} text={text} chevron={chevron} menu={menu} />
        )
      })}
    </StyledMobileMenu>
  )
}

export default MenuMobile
