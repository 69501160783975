import React from "react"

import { ArrowRightDiv } from "./slider.css"
import ChevronRight from "./chevron-right.svg"

const ArrowRight = ({ goRight }) => (
  <ArrowRightDiv onClick={goRight}>
    <ChevronRight />
  </ArrowRightDiv>
)

export default ArrowRight
