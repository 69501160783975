/* eslint-disable import/prefer-default-export, react/prop-types */
import React from "react"
import RootLayout from "../../src/layouts/RootLayout"
import Layout from "../../src/components/layout"

export const wrapRootElement = ({ element, props }) => {
  return <RootLayout {...props}>{element}</RootLayout>
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
