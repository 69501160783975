import styled from "@emotion/styled"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 220px;

  a {
    color: black;
    font-size: 0.8rem;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 16px;
  }

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    height: auto;
  }
`

export const Header = styled.div`
  width: 50%;
  background: #e6e6e6;
  text-align: center;
  font-size: 1.5rem;
  padding: 16px;
  border-radius: 6px 0 0 6px;
  position: relative;

  p {
    font-size: 0.8rem;
    margin-top: 8px;
    line-height: 1.3rem;
    margin-bottom: 8px;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
    font-size: 1rem;
    padding: 8px;

    p,
    a {
      display: none;
    }
  }
`

export const ImageContainer = styled.div`
  width: 50%;
`

export const Image = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  margin: 0 !important;
  background: url(${({ src }) => src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
`

export const Underline = styled.div`
  height: 1px;
  background: black;
  width: 100px;
  margin: 0 auto;
`

export const Description = styled.div`
  text-align: center;
  padding: 16px 32px;
  display: none;
  line-height: 1.5rem;

  p {
    margin-bottom: 3.45rem;
  }

  @media only screen and (max-width: 480px) {
    display: block;
  }
`

export const Heading = styled.div`
  text-align: center;
  margin-bottom: 20px;
`

export const MoreArticlesContainer = styled.div`
  text-align: center;
`

export const Articles = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ArticleContainer = styled.div`
`

export const Button = styled.button`
  color: white;
  border-radius: 500px;
  border: 1px solid #777;
  font-size: 0.8rem;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  width: 164px;
  margin: 0 auto;
  cursor: pointer;
  background: rgb(157, 157, 157);
  background: rgb(78, 78, 78);
  background: -moz-linear-gradient(
    345deg,
    rgba(78, 78, 78, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    345deg,
    rgba(78, 78, 78, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    345deg,
    rgba(78, 78, 78, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4e4e4e",endColorstr="#000000",GradientType=1);

  &:hover {
    background: black;
  }
`
