import { useReducer, useEffect, useState } from "react"
import { useSwipeable, SwipeableHandlers } from "react-swipeable"

// defines the time for the animation between slides in milliseconds
const transitionTime = 700
// animation to be used when bouncing back
const elastic = `transform ${transitionTime}ms cubic-bezier(0.68, -0.55, 0.265, 1.55)`
// animation to be used when automatically sliding
const smooth = `transform ${transitionTime}ms ease`

function previous(length, current) {
  return (current - 1 + length) % length
}

function next(length, current) {
  return (current + 1) % length
}

const initialCarouselState = {
  offset: 0,
  desired: 0,
  active: 0,
}

function carouselReducer(state, action) {
  switch (action.type) {
    case "jump":
      return {
        ...state,
        desired: action.desired,
      }
    case "next":
      return {
        ...state,
        desired: next(action.length, state.active),
      }
    case "prev":
      return {
        ...state,
        desired: previous(action.length, state.active),
      }
    case "done":
      return {
        ...state,
        offset: NaN,
        active: state.desired,
      }
    case "drag":
      return {
        ...state,
        offset: action.offset,
      }
    default:
      return state
  }
}

function threshold(target) {
  const width = target.clientWidth
  return width / 3
}

function swiped(e, dispatch, length, dir) {
  const t = threshold(e.event.target)
  const d = dir * e.deltaX

  if (d >= t - 50) {
    dispatch({
      type: dir > 0 ? "prev" : "next",
      length,
    })
  } else {
    dispatch({
      type: "drag",
      offset: 0,
    })
  }
}

export function useCarousel(length, interval, options) {
  let { slideWidth = 500, disableTransform = false } = options
  const totalWidth = length * 3 * slideWidth

  const [state, dispatch] = useReducer(carouselReducer, initialCarouselState)
  const handlers = useSwipeable({
    onSwiping(e) {
      dispatch({
        type: "drag",
        offset: e.deltaX,
      })
    },
    onSwipedLeft(e) {
      swiped(e, dispatch, length, -1)
    },
    onSwipedRight(e) {
      swiped(e, dispatch, length, 1)
    },
    trackMouse: true,
    trackTouch: true,
  })

  useEffect(() => {
    const id = setTimeout(() => dispatch({ type: "next", length }), interval)
    return () => clearTimeout(id)
  }, [state.offset, state.active])

  useEffect(() => {
    const id = setTimeout(() => dispatch({ type: "done" }), transitionTime)
    return () => clearTimeout(id)
  }, [state.desired])

  const f = length / 2 - 0.5

  let left = `calc((100vw * 0.5) - ${
    totalWidth / 2 + ((state.active * slideWidth) - (slideWidth * f))
  }px)`

  const style = {
    transform: "translateX(0)",
    width: `${totalWidth}px`,
    left,
  }

  if (state.desired !== state.active) {
    const dist = Math.abs(state.active - state.desired)
    const dir =
      (dist > length / 2 ? 1 : -1) * Math.sign(state.desired - state.active)
    const shift = -slideWidth * dir

    style.transition = smooth
    if (!disableTransform) {
      style.transform = `translateX(${-shift}px)`
    }
  } else if (!isNaN(state.offset)) {
    if (state.offset !== 0) {
      style.transform = `translateX(${state.offset}px)`
    } else {
      style.transition = elastic
    }
  }

  return [
    state.active,
    n => dispatch({ type: "jump", desired: n }),
    handlers,
    style,
  ]
}

export function makeIndices(start, delta, num) {
  const indices = []

  while (indices.length < num) {
    indices.push(start)
    start += delta
  }

  return indices
}
