import styled from "@emotion/styled"
import { slide as Menu } from "react-burger-menu"

import Logo from "./logo.inline.svg"
import Search from "./search.inline.svg"
import MenuIcon from "./menu.inline.svg"
import ChevronDown from "./chevron-down.inline.svg"
import ChevronUp from "./chevron-up.inline.svg"

export const MenuLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const MenuContainer = styled.div`
  /*display: flex;*/
  /*margin: 16px 0;*/
  padding: 16px 0;
  display: block;
  width: 100%;
  text-align: center;

  a {
    margin: 0 30px;/*12px;*/
    /*text-transform: uppercase;*/
    text-decoration: none;
    color: black;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;

    r &:hover {
      color: #777;
    }
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const Container = styled.header`
`

export const LogoSVG = styled(Logo)`
  width: 200px;
  height: auto;
  position: relative;
`

export const SearchSVG = styled(Search)`
  width: 25px;
  height: 18px;

  @media only screen and (max-width: 768px) {
    position: absolute;
    right: 16px;
  }
`

export const MenuSVG = styled(MenuIcon)`
  width: 24px;
  position: absolute;
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
    right: 20px;
    top: 7px;
  }
`

export const ChevronDownSVG = styled(ChevronDown)`
  width: 10px;
  margin: 0 8px;
`

export const ChevronUpSVG = styled(ChevronUp)`
  width: 10px;
  margin: 0 8px;
`

export const StyledMobileMenu = styled(Menu)`
  .bm-menu {
    background: white;
  }

  .bm-morph-shape {
    fill: #373a47;
  }

  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }

  .bm-cross-button {
    height: 24px;
    width: 24px;
    display: none;
    top: 15px;
    right: 15px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #222;
  }

  a {
    text-decoration: none;
    color: black;
    font-size: 16px;
    display: block;
  }
`

export const MobileMenuDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  margin-left: 16px;
  margin-top: 16px;
`
