import React from "react"
import { graphql, StaticQuery } from "gatsby"

import { Container } from "./brands-menu.css"
import { sortByName } from "./sort"

const sectionQuery = graphql`
  query {
    allBuilderModels {
      section(limit: 10, query: {data: {featured: true}} ,options: { cachebust: false }) {
        data {
          name
          code
        }
      }
      article(limit: 3, sort: {createdDate: -1}, query: {data: {featuredOnLanding: true}}, options: { cachebust: false }) {
        data {
          slug
          picture
          title
        }
      }
    }
  }
`

const SectionMenu = ({ isMenuOpen }) => {
  return (
    <StaticQuery query={sectionQuery}>
      {data => {
        const models = data.allBuilderModels
        if (models.section && models.article) {
          models.section.sort(sortByName)
          return (
            <div class="subMenuContent">
              <div class="subMenuLinks">
                <h2>Featured Sections</h2>
                <div class="menuItems">
                {models.section.map((item, i) => {
                  const section = item.data
                  if (section.name) {
                    return (
                      <a key={i} href={`/sections/${section.code}`}>
                        {section.name}
                      </a>
                    )
                  }
                  return null
                })}
                </div>
              </div>
              <div class="subMenuArticles">
                <h2>Featured</h2>
                <div class="Articles">
                {models.article.map((item, i) => {
                  const article = item.data
                  if (article.picture) {
                    return (
                    <div class="Article">
                      <a key={i} href={`/articles/${article.slug}`}>
                        <img src={article.picture} alt={article.name} />
                        <span>{article.title}</span>
                      </a>
                    </div>
                    )
                  }
                  return null
                })}
                </div>
              </div>
            </div>
          )
        }
      }}
    </StaticQuery>
  )
}

export default SectionMenu
