import { Builder } from "@builder.io/react"
import Brands from "./brands"

Builder.registerComponent(Brands, {
  name: "Brands",
  inputs: [
    {
      name: "sectionName",
      type: "string",
      defaultValue: ''
    },
    {
      name: "heading",
      type: "string",
      defaultValue: "Top Articles",
    },
  ],
})
