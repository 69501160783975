import { Builder } from "@builder.io/react"
import Hero from "./hero"

Builder.registerComponent(Hero, {
  name: "Hero",
  inputs: [
    {
      name: "sectionName",
      type: "string",
      defaultValue: ''
    },
    {
      name: "heading",
      type: "string",
      defaultValue: ''
    },
    {
      name: "subheading",
      type: "string",
      defaultValue: ''
    },
    {
      name: "image",
      type: "file",
      allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
    },
    {
      name: "article",
      type: "reference",
      modelId: "fab8c26226a84ff5a0745a16e55097a0",
      defaultValue: {
        "@type": "@builder.io/core:Reference",
        "model": "article"
      },
    },
  ],
})
