/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "../builder-settings"

import Header from "./Header/header"
import Footer from "./Footer/footer"
import "../css/wgfonts.css"
import "./layout.css"

import "@fontsource/raleway"
import "@fontsource/khula"
import "@fontsource/im-fell-double-pica"

const Layout = ({ children, location }) => {
  const isHomePage = location.pathname === "/"
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
