import { Builder } from "@builder.io/react"
import MoreArticles from "./more-articles"

Builder.registerComponent(MoreArticles, {
  name: "MoreArticles",
  inputs: [
    {
      name: "sectionName",
      type: "string",
      defaultValue: ''
    },
    {
      name: "heading",
      type: "string",
      defaultValue: "More Articles",
    },
    {
      name: "articles",
      type: "list",
      defaultValue: [],
      subFields: [
        {
          name: "article",
          type: "reference",
          modelId: "fab8c26226a84ff5a0745a16e55097a0",
          defaultValue: {
            "@type": "@builder.io/core:Reference",
            "model": "article"
          },
        },
      ],
    },
  ],
})
