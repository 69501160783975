import React from "react"
import { Link } from "gatsby"

import {
  FLogoSVG,
  MailIconSVG,
  FBLogoSVG,
  TWLogoSVG,
  LILogoSVG,
  YTLogoSVG,
  PILogoSVG,
  Container,
  MenuContainer,
  InnerMenuContainer,
} from "./footer.css"

const Footer = () => (
  <Container>
    <MenuContainer>
      <div class="footerSection footerGap">
        <div class="container">
          <div class="footerRow">
            <div class="footerCol left">
              <div class="footerLogo">
                <FLogoSVG />
              </div>
              <div class="companyText">
                Luxury Swiss Watches News<br/>
                <a class="companyEmail" href="mailto:gabriele@watchesguild.com">
                  <span class="emailIcon">
                    <MailIconSVG />
                  </span>
                  gabriele@watchesguild.com
                </a>
              </div>
            </div>
            <div class="footerCol left">
              <div class="footerTitle">Company</div>
              <div class="companyLinks">
                <ul>
                  <li><a href="/">Home</a></li>
                  <li><a href="/brands">Brands</a></li>
                  <li><a href="/sections">Sections</a></li>
                  <li><a href="/about-us">About Us</a></li>
                  <li><a href="/latest">Latest Releases</a></li>
                  <li><a href="/company">Company</a></li>
                  <li><a href="/contact">Contact Us</a></li>
                </ul>
              </div>
            </div>
            <div class="footerCol left">
              <div class="footerTitle">Follow us</div>
              <div class="footerIcons">
                <div class="footerIcon">
                  <Link
                    href="https://www.facebook.com/WatchesGuild" target="_blank"
                  >
                    <FBLogoSVG />
                  </Link>
                </div>
                <div class="footerIcon">
                  <Link
                    href="https://twitter.com/WatchesGuild" target="_blank"
                  >
                    <TWLogoSVG />
                  </Link>
                </div>
                <div class="footerIcon">
                  <Link
                    href="https://www.pinterest.com/watchesguild" target="_blank"
                  >
                    <PILogoSVG />
                  </Link>
                </div>
                <div class="footerIcon">
                  <Link
                    href="https://www.youtube.com/channel/UCzjncA4QQyeq-VPMVicN35w" target="_blank"
                  >
                    <YTLogoSVG />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MenuContainer>
    <MenuContainer>
      <div class="footerSection footerBottom">
        <div class="container">
          <div class="footerRow">
            <div class="footerCol left">© {new Date().getFullYear()} All rights reserved by watchesguild</div>
            <div class="footerCol center"><a href="/terms">Terms & Conditions</a></div>
            <div class="footerCol right"><a href="/privacy">Privacy Policy</a></div>
          </div>
        </div>
      </div>
    </MenuContainer>
  </Container>
)

export default Footer
