import React from "react"
import Slider from "react-slick"
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Container, Underline, Heading } from "./brands.css"
import audemarspiguet from "../../images/logos/audemarspiguet.png"
import breitling from "../../images/logos/breitling.png"
import bulova from "../../images/logos/bulova.png"
import bvlgari from "../../images/logos/bvlgari.png"
import cartier from "../../images/logos/cartier.png"
import casio from "../../images/logos/casio.png"
import chopard from "../../images/logos/chopard.png"
import enicar from "../../images/logos/enicar.png"
import hamilton from "../../images/logos/hamilton.png"
import hublot from "../../images/logos/hublot.png"
import konstantin from "../../images/logos/konstantin-chaykin.png"
import longines from "../../images/logos/longines.png"
import omega from "../../images/logos/omega.png"
import oris from "../../images/logos/oris.png"
import panerai from "../../images/logos/panerai.png"
import patekphilippe from "../../images/logos/patekphilippe.png"
import RichardMille from "../../images/logos/richardmille.png"
import Rolex from "../../images/logos/Rolex.png"
import seiko from "../../images/logos/seiko.png"
import sinn from "../../images/logos/sinn.png"
import swatch from "../../images/logos/swatch.png"
import tagheurer from "../../images/logos/tagheurer.png"
import urwerk from "../../images/logos/urwerk.png"
import zenith from "../../images/logos/zenith.png"

const brandsettings = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  rows: 2,
  arrows: true,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 640,
      settings: {
        dots: true,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};


const Brands = () => (
  <div class="Brands container"><Container className="brands">
    <Heading className="brandTitle">
      {['Popular ', <b>Global Brands</b>]}
    </Heading>
    <Slider {...brandsettings} className="brandSlider">
      <div className="brand">
        <a href="/brands/audemarspiguet">
          <img src={audemarspiguet} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/breitling">
          <img src={breitling} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/bulova">
          <img src={bulova} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/cartier">
          <img src={cartier} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/patekphilippe">
          <img src={patekphilippe} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/richardmille">
          <img src={RichardMille} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/rolex">
          <img src={Rolex} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/chopard">
          <img src={chopard} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/longines">
          <img src={longines} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/zenith">
          <img src={zenith} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/oris">
          <img src={oris} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/casio">
          <img src={casio} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/omega">
          <img src={omega} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/konstantin-chaykin">
          <img src={konstantin} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/hamilton">
          <img src={hamilton} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/bvlgari">
          <img src={bvlgari} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/seiko">
          <img src={seiko} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/sinn">
          <img src={sinn} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/swatch">
          <img src={swatch} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/enicar">
          <img src={enicar} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/panerai">
          <img src={panerai} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/tagheurer/">
          <img src={tagheurer} />
        </a>
      </div>
      <div className="brand">
        <a href="/brands/urwerk/">
          <img src={urwerk} />
        </a>
      </div>
    </Slider>
  </Container></div>
)

export default Brands
